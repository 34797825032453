<template>
  <b-overlay :show="isLoading">
    <b-row class="mb-4">
      <b-col>
        <b-badge variant="primary" class="badge-padding">
          Order {{ order.id }}
        </b-badge>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6" class="px-4 mb-2">
        <div class="mb-2">
          <h3>
            <FeatherIcon icon="TruckIcon" size="30" />
            <span class="ml-2">Order Tracking</span>
          </h3>
        </div>

        <AppTimeline>
          <AppTimelineItem
            v-for="(event, eventIndex) in order.events"
            :key="eventIndex"
            :title="event.message"
            :time="$helpers.formatDateTime(event.created_at)"
          />
        </AppTimeline>
      </b-col>

      <b-col cols="12" lg="6" class="px-4 mb-2">
        <div class="mb-2">
          <h3>
            <FeatherIcon icon="BoxIcon" size="30" />
            <span class="ml-2">Order Summary</span>
          </h3>
        </div>

        <b-row>
          <b-col cols="12" lg="6">
            <AppTimeline>
              <AppTimelineItem title="Order Overview" icon="ArchiveIcon">
                <div class="mb-2">
                  <h4>Order Overview</h4>
                </div>

                <div v-if="order.order_no" class="mb-2">
                  <h5>Order No.</h5>
                  <span>
                    {{ order.order_no ? order.order_no : '' }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Status</h5>
                  <b-badge variant="primary" class="badge-padding">
                    {{ order.status }}
                  </b-badge>
                </div>

                <div class="mb-2">
                  <h5>Shipment</h5>
                  <b-badge variant="danger" class="badge-padding">
                    {{ order.order_type }}
                  </b-badge>
                </div>

                <div class="mb-2">
                  <h5>Total Spent</h5>
                  <span>
                    {{ $helpers.formatTotal(order.amount) }}
                  </span>
                </div>
              </AppTimelineItem>

              <AppTimelineItem
                v-if="order.order_type.includes('distributor')"
                title="Product Details"
                icon="BoxIcon"
              >
                <div class="mb-2">
                  <h4>Product Details</h4>
                </div>

                <div class="mb-2">
                  <h5>Product Category</h5>
                  <span>
                    {{ order.product_category }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Packaging</h5>
                  <span>
                    {{ order.packaging }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Quantity</h5>
                  <span>
                    {{ order.quantity }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Size</h5>
                  <span>
                    {{ order.order_size }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Weight</h5>
                  <span>
                    {{ order.order_weight }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Temperature</h5>
                  <span>
                    {{ order.temperature }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Remarks</h5>
                  <span>
                    {{ order.remarks }}
                  </span>
                </div>
              </AppTimelineItem>
            </AppTimeline>
          </b-col>

          <b-col cols="12" lg="6">
            <AppTimeline>
              <AppTimelineItem title="Address Details" icon="MapIcon">
                <div class="mb-2">
                  <h4>Address Details</h4>
                </div>

                <div class="mb-2">
                  <h5>Sender Name</h5>
                  <span>
                    {{
                      `${order.sender.first_name} ${
                        order.sender.last_name ? order.sender.last_name : ''
                      }`
                    }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Sender Address</h5>
                  <span>
                    {{ order.sender.complete_address }}
                  </span>
                </div>

                <hr />

                <div class="mb-2">
                  <h5>Recipient Name</h5>
                  <span>
                    {{
                      `${order.recipient.first_name} ${
                        order.recipient.last_name
                          ? order.recipient.last_name
                          : ''
                      }`
                    }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Recipient Address</h5>
                  <span>
                    {{ order.recipient.complete_address }}
                  </span>
                </div>
              </AppTimelineItem>
            </AppTimeline>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
  name: 'DistributorOrderSummary',
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    order: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: false,
      summary: [],
    };
  },
};
</script>

<style lang="scss" scoped></style>
